import React from "react";
import background from "../../images/Home/bg2.jpg";
import officeFurniture from "../../images/Home/mobile-shelving.jpg";
import officeLogo from "../../images/Stairlifts/updated-logo-black-shelving-system-logo.png";
import stairLift from "../../images/Stairlifts/front.webp";
import stairLiftLogo from "../../images/Home/logo-stair-lift.png"
import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <div className="relative font-thin text-justify flex justify-center items-center ">
      <div
        className="relative w-full h-[80vh] bg-cover bg-center bg-fixed"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="absolute inset-0 bg-[#3433338f]"></div>
      </div>

      <div className="absolute w-[100%] flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center  h-[100%] text-white pb-[3%] text-justify md:w-[87%] w-[69.8%] 2xl:w-[73.8%] xl:w-[83.8%]">
          <p className="text-[2.5rem] md:text-[1.8rem] my-[1%] text-center">
            Who Are We
          </p>
          <p className="w-[100%] md:text-[12px] ">
            Albert Massaad Misr™ was founded in 1963, specializing in the manufacturing of steel furniture for both private and public sectors. Since its inception, the company has been dedicated to the design and production of office and medical furniture, while delivering efficient solutions and services to global customers.
            In 2001, Albert Massaad Misr™ achieved a new level of international professionalism with the introduction of its mobile shelving system.
          </p>
          <div className="w-[100%] text-black flex justify-center gap-[5%] p-[2%] md:mt-[5px]">
            <Link to="/about-us/shelving-systems" className="w-[220px] lg:w-[150px] bg-[white] flex justify-between items-center flex-col">
              <img
                className="w-[100%] object-contain h-[100%]"
                src={officeFurniture}
                alt="Shelving-system"
              />
              <img
                className="w-[85%] object-contain h-[100%]"
                src={officeLogo}
                alt="Shelving-system-Logo"
              />
              <h1 className=" py-[5%] md:text-[10px]">Shelving Systems</h1>
            </Link>
            <Link to="/about-us/accessibility" className="w-[220px] lg:w-[150px] bg-[white] flex justify-between items-center flex-col">
              <img
                className="w-[100%] object-contain h-[100%]"
                src={stairLift}
                alt="Stairlifts"
              />
              <img className="w-[25%] object-contain mt-[5%]" src={stairLiftLogo} alt="Stair-Lift-Logo" />
              <h1 className=" py-[5%] md:text-[10px]">Accessibility</h1>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
